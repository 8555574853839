.swiper {
  width: 100%;
  height: 50%;
  position: absolute;
}

.swiper-slide.swiper-slide-active {
  transform: translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(0deg) scale(0.8) !important;
}

.swiper-slide.swiper-slide-prev {
  transform: translate3d(208.46px, 20px, -247px) rotateX(0deg) rotateY(0deg)
    scale(0.7) !important;
}

.swiper-slide.swiper-slide-next {
  transform: translate3d(-208.46px, 20px, -247px) rotateX(0deg) rotateY(0deg)
    scale(0.7) !important;
}

.swiper_container1 {
  height: 100%;
  position: relative;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  display: none;
}

.slider-controller {
  bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-controller .slider-arrow {
  width: 48px !important;
  height: 48px !important;
  border-radius: 100% !important;
  color: white;
}

.swiper-button-prev {
  left: 70px;
}

.swiper-button-next {
  right: 70px;
}
