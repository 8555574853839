@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
:root {
  --colorPrimary: #000f1d;
  --white: #ffffff;
}
body {
  margin: 0;
  padding: 0;
}
img {
  max-width: 100%;
}

.video-layout {
  border-radius: 28.904px;
  border: 0.963px solid #fff;
  background: rgba(49, 167, 73, 0.1);
  backdrop-filter: blur(6.262501239776611px);
  width: 100%;
  height: auto;
  flex-shrink: 0;
}

.deco-video {
  mix-blend-mode: lighten;
}

.image-transition {
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.transform-custom:hover .image-transition {
  opacity: 0.8;
}

.calendar {
  mix-blend-mode: lighten;
}

.calendar-mb {
  mix-blend-mode: lighten;
}

.calendar-bg-mb {
  mix-blend-mode: lighten;
  height: 160px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #00931e 60%);
  z-index: 10;
}

.calendar-bg {
  height: 140px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 40%, #00931e 100%);
}

@media (min-width: 1900px) {
  .calendar-bg {
    height: 200px;
  }
}

.item1-layout {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  width: 50%;
}

.item1-layout::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 30;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.item1-layout:hover .item1 {
  opacity: 1;
  animation: fadeIn 0.5s ease-out;
  transition: transform 0.5s ease-out, transform 0.5s ease-out;
}

.ant-modal {
  margin-top: 80px;
  width: 40% !important;
}

.ant-modal-content {
  border-radius: 50px !important;
  border: 0.963px solid #fff !important;
  background: rgba(49, 167, 73, 0.1) !important;
  backdrop-filter: blur(6.262501239776611px) !important;
  width: 100%;
  flex-shrink: 0;
  padding-top: 40px !important;
}

.ant-modal-body {
  height: 60vh;
  overflow-y: scroll;
}

.ant-modal-footer {
  display: none;
}

table tr td {
  color: #fff;
}

table tr td {
  border-bottom: 1px solid #8f8e8e;
  padding: 10px 0;
}

table tr td:nth-child(1) {
  width: 7%;
}

table tr td:nth-child(2) {
  width: 10%;
  padding: 0 30px;
  text-align: center;
}

table tr td:nth-child(3) {
  width: 20%;
  text-align: center;
}

table tr td:nth-child(3) p {
  border-radius: 23.993px;
  border: 1px solid #b5b8d5;
  box-shadow: 0px 0px 4px 0px rgba(255, 255, 255, 0.25) inset;
  padding: 5px 10px;
}

table tr td:nth-child(4) {
  width: 20%;
  text-align: center;
}

table tr td:nth-child(5) {
  width: 10%;
  text-align: center;
}

table tr td:nth-child(6) {
  width: 20%;
  text-align: center;
}

table tr td:nth-child(7) {
  text-align: center;
  width: 20%;
}

table tr td:nth-child(7) p {
  border-radius: 23.993px;
  border: 1px solid #b5b8d5;
  box-shadow: 0px 0px 4px 0px rgba(255, 255, 255, 0.25) inset;
  padding: 5px 10px;
}

/* Custom scrollbar cho toàn bộ trang */
::-webkit-scrollbar {
  width: 5px !important;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #31a749;
}

::-webkit-scrollbar-thumb:hover {
  background: #31a749;
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #31a749 transparent;
}

@media (max-width: 768px) {
  .ant-modal {
    width: 100% !important;
  }

  table tr td:nth-child(1) {
    width: 5%;
    text-align: center;
  }

  table tr td:nth-child(2) {
    width: 20% !important;
    padding: 0 !important;
    text-align: center;
  }

  table tr td:nth-child(2) p {
    border-radius: 23.993px;
    border: 1px solid #b5b8d5;
    box-shadow: 0px 0px 4px 0px rgba(255, 255, 255, 0.25) inset;
    padding: 0 !important;
  }

  table tr td:nth-child(3) {
    width: 20% !important;
    text-align: center;
  }

  table tr td:nth-child(4) {
    width: 20% !important;
    text-align: center;
  }
  table tr td:nth-child(4) p {
    border-radius: 23.993px;
    border: 1px solid #b5b8d5;
    box-shadow: 0px 0px 4px 0px rgba(255, 255, 255, 0.25) inset;
    padding: 0 !important;
  }
}

.modal-policy.ant-modal {
  margin-top: 10px;
  border-radius: 13.201px;
  padding-bottom: 0;
  width: 55% !important;
}

.modal-policy .ant-modal-content {
  padding: 0 !important;
  border-radius: 13px !important;
  border: 0 !important;
  background: rgba(50, 119, 63, 0.8) !important;
  box-shadow: 0px 3.667px 0px 0px #5c6746;
  backdrop-filter: blur(0.7333823442459106px);
  opacity: 0.9;
}

.modal-policy .ant-modal-body {
  box-shadow: 0px 3.667px 0px 0px #5c6746;

  border-radius: 13.201px;
  border: 2.2px solid #acff9e;
  padding-bottom: 0;
  width: 100% !important;
  max-height: 65vh;
  overflow-y: scroll;
}

.modal-policy .ant-modal-title {
  padding: 15px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600 !important;
  font-size: 1rem;
}

.modal-policy .ant-modal-body p {
  font-size: 0.95rem;
}

@media screen and (max-width: 768px) {
  .modal-policy.ant-modal {
    width: 90% !important;
  }

  .modal-policy .ant-modal-title {
    font-size: 0.8rem;
  }

  .modal-policy .ant-modal-body {
    font-size: 0.8rem;
  }
}
